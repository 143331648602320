export const environment = {
  apiParq: 'https://api-uat.parqco.com/api',
  clients: {
    operator: 'https://uat.d2aol8f6bio8ep.amplifyapp.com',
  },
  email: '@users.parqco.com',
  kushkiMerchantId: '21926e14fcbb46a89bc123945da8a77a',
  production: true,
  parq: {
    anprEmailPrefix: 'anpr.',
    driverId: 760,
    guestsDomain: '@guests.parqco.com',
    serviceChargePercentage: 5,
  },
  microliths: {
    entities: 'https://api-uat.parqco.com/api/entities/v2',
    management: 'https://api-uat.parqco.com/api/management/v2',
    payments: 'https://api-uat.parqco.com/api/payments/v2',
    services: 'https://api-uat.parqco.com/api/services/v2',
    users: 'https://api-uat.parqco.com/api/users/v2',
  },
};
